<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>
      </v-card-title>
      <v-divider v-if="action === ``" />

      <v-card-text v-if="action === ``" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="headers"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === `inventory_source_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.inventory_source_id > 0">
                {{ row.prop.item.inventory_source_name }} ({{
                  row.prop.item.inventory_source_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `deleted_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_at">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="success"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />
 
          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ``" />

      <div v-if="action !== ``" class="ml-5 pb-5 mr-5 o-container-content">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row> 
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.name"
                  :busy="busy"
                  label="Name"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col> 
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.key"
                  :busy="busy"
                  label="Key"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col>  
              <v-col cols="12" class="pa-0">
                <o-check-box
                  :busy="busy"
                  v-model="model.active"
                  label="Active"
                  ></o-check-box>
              </v-col> 
            </v-row>
          </v-container>
        </v-form>
      </div>

      <o-drawer
        v-if="!busy"
        :auto-close="true"
        :actions="[
          {
            key: 'on-item-reset',
            label: 'RESET',
            icon: 'mdi-refresh',
            color: 'warning',
          },
          {
            key: 'on-item-search',
            label: 'SEARCH',
            icon: 'mdi-text-box-search-outline',
            color: 'success',
          },
        ]"
        button-icon="mdi-filter-plus-outline"
        :title="`FILTERS`"
        :use-button="false"
        width="50vw"
        :visible="showFilter"
        @on-item-search="
          showFilter = false;
          reset();
        "
        @on-item-reset="
          showFilter = false;
          reset(true);
        "
        @on-close="showFilter = false"
      >
        <v-row class="pa-0 ma-0"> 
          <v-col cols="12">
            <o-input
              v-model="parameters.name"
              :busy="busy"
              label="Name"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12">
            <o-input
              v-model="parameters.key"
              :busy="busy"
              label="Key"
              :hasClear="true"
            />
          </v-col>
        </v-row>
      </o-drawer>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: `MiscellaneousMetro`,
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: `Metros`,
  },
  data() {
    return {
      title: `Metros`,
      busy: false,
      strictFilter: false,
      action: ``,
      valid: false,
      baseModel: {
        id: 0,
        name: ``,       
        key: ``,
        active: false,
      },
      model: {},
      originalModel: {},
      parameters: {      
        name: ``,       
        key: ``
      },
      headers: [
        {
          text: `#`,
          align: `end`,
          value: `row_no`,
          type: `int`,
          width: 100,
        },
         {
          text: `ID`,
          align: `end`,
          value: `id`,
          type: `int`,
          width: 100,
        },
        {
          text: `Name`,
          align: `start`,
          value: `name`,
          type: `text`,
        },
        {
          text: `Key`,
          align: `start`,
          value: `key`,
          type: `text`,
        },
        {
          text: `Active`,
          align: `center`,
          value: `active`,
          type: `boolean`,
        },
        {
          value: `action`,
          type: `action`,
          width: 100,
          actions: [
            {
              key: `on-item-edit`,
              label: `Edit`,
              icon: `mdi-pencil-box-outline`,
              color: `success`,
            },
            {
              key: `on-item-delete`,
              label: `Delete`,
              icon: `mdi-trash-can-outline`,
              color: `danger`,
            },
          ],
        },
      ], 
    };
  },
  computed: {
    ...mapGetters([`loggedInUser`]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      `ROW_NUMBER() OVER(ORDER BY a.name) row_no`,
      `a.*`,
    ];
    this.listParameters.sources = {
      main: `metros a`,
      children: [],
    };
    this.listParameters.orders = [
      {
        sort: `a.name`,
        dir: `ASC`,
      },
    ];
  },
  async mounted() { 
  },
  methods: {
    add() {
      this.action = `add`;
      this.model = _.cloneDeep(this.baseModel);
    },
    edit(item) {
      this.model = _.cloneDeep(item);
      this.originalModel = _.cloneDeep(item);
      this.action = `edit`;
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = `/rest/intranet/query`;
      const model = _.cloneDeep(this.model);
      if (this.action === `add`) {
        const parameters = _.cloneDeep(this.addParameters);
        parameters.table = `metros`;
  
        parameters.fields.push({
          field: `name`,
          value: this.model.name,
        });

        parameters.fields.push({
          field: `key`,
          value: this.model.key,
        });

        parameters.fields.push({
          field: `active`,
          value: this.model.active,
        });
        
        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${model.name} (${model.id}).`,
              `green`,
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === `edit`) {
        const model = _.cloneDeep(this.model);

        const parameters = _.cloneDeep(this.updateParameters);
        parameters.table = `metros`;
       
        parameters.fields.push({
          field: `name`,
          value: this.model.name,
        });
        
        parameters.fields.push({
          field: `key`,
          value: this.model.key,
        });

        parameters.fields.push({
          field: `active`,
          value: this.model.active,
        });
        
        parameters.wheres.push({ field: `id`, value: this.model.id });

        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated ${model.name} (${model.id}).`,
              `green`,
              2000
            );
            this.reset();
          })
          .catch((err) => {           
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `Error updating item: ${errors.join(",")}`,
          `red`,
          2000
        );
      } else {
        this.reset();
      }
    },
    async remove(item) {
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          `Confirmation`,
          `You are deleting <strong>${this.model.name} (${this.model.id})</strong>. Do you want to continue?`,
          `red`
        )
        .then(async (ans) => {
          if (ans) {
            const url = `/rest/intranet/query`;
            const parameters = _.cloneDeep(this.deleteParameters);
            parameters.table = `metros`; 
            parameters.wheres.push({ field: `id`, value: this.model.id });

            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.model.name} (${this.model.id}) was successfully deleted.`,
                  `red`,
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `Error updating item: ${errors.join(`,`)}`,
                  `red`,
                  2000
                );
              });
          }
        });
    },
    reset(clear) {
      this.listParameters.wheres = [];
      if (clear) {
        this.parameters = { 
          name: "", 
          key: "",
        };
      }
      const wheres = [];
      if (this.parameters.name != ``) {
        wheres.push(
          this.getWhereString(`a.name`, this.parameters.name)
        );
      }

      if (this.parameters.key != ``) {
        wheres.push(
          this.getWhereString(`a.key`, this.parameters.key)
        );
      }
     
      if (wheres.length > 0)
        this.listParameters.wheres = [
          wheres.join(` ${this.strictFilter ? ` AND ` : ` OR `} `),
        ];

      this.action = ``;
      this.model = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
