<template>
  <MiscellaneousMetro dialog-name="Metros" />
</template>
<script>
import MiscellaneousMetro from '@/views/app/miscellaneous/Metro'

export default {
  name: 'MetroWrapper',
  components: {
    MiscellaneousMetro
  }
}
</script>
